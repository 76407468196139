<template>
  <div>
    <!-- <a-calendar>
      <ul slot="dateCellRender" slot-scope="value" class="events">
        <li v-for="item in datalist.permonth" :key="item.name">
          <a-badge :status="item.type" :text="item.datalist !== undefined ? item.datalist.length + 'x Transaksi' : 'Belum melakukan transaksi'" />
        </li>
      </ul>
      <template slot="monthCellRender" slot-scope="value">
        <div class="notes-month">
          <section>{{ item.name }} - {{ value.toString }}</section>
          <span>{{ item.datalist !== undefined ? item.datalist.length + 'x Transaksi' : 'Belum melakukan transaksi' }}</span>
        </div>
      </template>
    </a-calendar> -->
    <div class="row">
      <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <label class="ml-2 pt-1">Filter Month: </label>
        <a-month-picker
          class="ml-2"
          style="width: 150px;"
          v-model="inputbulanan"
          :allowClear="true"
          format="MM-YYYY"
          @change="
            (dateMoment, dateString) =>
              changePeriodeMonth(dateMoment, dateString, 'inputbulanan')
          "
        />
      </div> -->
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <a-card>
          <a-calendar :fullscreen="false" :mode="calendarviewmodemonth" @panelChange="onPanelChange" @select="onCalendarSelect"/>
        </a-card>
        <a-card class="mt-2">
          <template slot="title">
            <strong>Bulan: {{ inputbulanan.locale('id').format('MMMM YYYY') }}</strong>
          </template>
          <div
            class="demo-infinite-container"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
          >
            <a-list size="small" :data-source="datalist.permonth">
              <a-list-item slot="renderItem" slot-scope="item" @click="listOnClick(item)">
                <a-list-item-meta
                  :description="''"
                >
                  <a style="padding: unset;margin: unset;" slot="title" href="javascript:void(0)">{{ item.name }}</a>
                  <!-- <a-avatar
                  slot="avatar"
                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                /> -->
                </a-list-item-meta>
                <div>{{ item.datalist !== undefined ? item.datalist.length + 'x Transaksi' : 'Belum melakukan transaksi' }}</div>
              </a-list-item>
              <div v-if="loading && !busy" class="demo-loading-container">
                <a-spin />
              </div>
            </a-list>
          </div>
        </a-card>
      </div>
      <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
        <label class="ml-2 pt-1">Filter Date: </label>
        <a-date-picker
          class="ml-2"
          style="width: 150px;"
          v-model="tgl1"
          :allowClear="true"
          format="DD-MM-YYYY"
          @change="
            (dateMoment, dateString) =>
              changePeriode(dateMoment, dateString, 'tgl1')
          "
        />
        <label class="pt-1 ml-2">-</label>
        <a-date-picker
          class="ml-2"
          style="width: 150px;"
          v-model="tgl2"
          :allowClear="true"
          format="DD-MM-YYYY"
          @change="
            (dateMoment, dateString) =>
              changePeriode(dateMoment, dateString, 'tgl2')
          "
        />
        <a-card class="mt-2">
          <template slot="title">
            <strong>Antara Tanggal: {{ this.tgl1.locale('id').format('DD MMMM YYYY') + ' - ' + this.tgl2.locale('id').format('DD MMMM YYYY') }}</strong>
          </template>
          <div
            class="demo-infinite-container"
            :infinite-scroll-disabled="busy"
            :infinite-scroll-distance="10"
          >
            <a-list size="small" :data-source="datalist.perday">
              <a-list-item slot="renderItem" slot-scope="item" @click="listOnClick(item)">
                <a-list-item-meta
                  :description="''"
                >
                  <a style="padding: unset;margin: unset;" slot="title" href="javascript:void(0)">{{ item.name }}</a>
                  <!-- <a-avatar
                  slot="avatar"
                  src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                /> -->
                </a-list-item-meta>
                <div>{{ item.datalist !== undefined ? item.datalist.length + 'x Transaksi' : 'Belum melakukan transaksi' }}</div>
              </a-list-item>
              <div v-if="loading && !busy" class="demo-loading-container">
                <a-spin />
              </div>
            </a-list>
          </div>
        </a-card>
      </div>
    </div>
    <a-modal v-model="modaldetails.visible" :dialogStyle="{ top: '10px' }" :footer="null" :width="500" :bodyStyle="{ padding: '0px' }">
        <template slot="title">
          <strong>Detail list transaski {{ modaldetails.operatorname }} </strong>
        </template>
        <div
          class="demo-infinite-container1"
          :infinite-scroll-disabled="busy"
          :infinite-scroll-distance="10"
        >
          <a-list size="small" :data-source="modaldetails.data" :key="componentKey">
            <a-list-item slot="renderItem" slot-scope="item">
              <a-list-item-meta
                :description="item.keterangan"
              >
                <label style="padding: unset;margin: unset;" slot="title" href="javascript:void(0)">{{ item.nama }}</label>
                <!-- <a-avatar
                slot="avatar"
                src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
              /> -->
              </a-list-item-meta>
              <a-popover title="Detail data" placement="right">
                <template #content>
                  <strong>Rekening: </strong> <label style="margin: unset; padding: unset;">{{ item.rekening }}</label>
                  <a-divider type="horizontal" style="margin: unset !important;"/>
                  <strong>Nama: </strong> <label style="margin: unset; padding: unset;">{{ item.nama }}</label>
                  <a-divider type="horizontal" style="margin: unset !important;"/>
                  <strong>Tanggal/Faktur</strong> <label style="margin: unset; padding: unset;">{{ moment(item.tgl, 'YYYY-MM-DD').locale('id').format('DD MMMM YYYY') + ' - ' + item.faktur }}</label>
                  <a-divider type="horizontal" style="margin: unset !important;"/>
                  <strong>Keterangan: </strong> <label style="margin: unset; padding: unset;">{{ item.keterangan }}</label>
                  <a-divider type="horizontal" style="margin: unset !important;"/>
                  <strong>Jumlah: </strong> <label style="margin: unset; padding: unset;">{{ formatCurrency(item.jumlah) }}</label>
                  <a-divider type="horizontal" style="margin: unset !important;"/>
                  <strong>Saldo Sebelum: </strong> <label style="margin: unset; padding: unset;">{{ formatCurrency(item.saldoawal) }}</label>
                  <a-divider type="horizontal" style="margin: unset !important;"/>
                  <strong>Saldo Setelah: </strong> <label style="margin: unset; padding: unset;">{{ formatCurrency(item.saldoakhir) }}</label>
                  <a-divider type="horizontal" style="margin: unset !important;"/>
                  <strong>KTP: </strong> <label style="margin: unset; padding: unset;">{{ item.ktp }}</label>
                  <a-divider type="horizontal" style="margin: unset !important;"/>
                  <strong>Lokasi input: </strong> <a :href="'https://www.google.com/maps/search/?api=1&query=' + item.latitude + ',' + item.longitude" target="_blank">Show on map</a>
                  <a-divider type="horizontal" style="margin: unset !important;"/>
                  <strong>Timestamps: </strong> <label style="margin: unset; padding: unset;">{{ item.created_at }}</label>
                </template>
              <div style="text-decoration: underline; cursor: pointer">{{ formatCurrency(item.jumlah) }}</div>
              </a-popover>
            </a-list-item>
            <div v-if="loading && !busy" class="demo-loading-container">
              <a-spin />
            </div>
          </a-list>
        </div>
        <!-- <template slot="footer">
          <a-button class="btn btn-outline-dark">Cancel</a-button>
          <a-button class="btn btn-outline-primary">Process</a-button>
        </template> -->
      </a-modal>
  </div>
</template>

<script>
import * as lou from '@/services/data/lou'
// import vueCustomScrollbar from 'vue-custom-scrollbar'
import moment from 'moment'
import infiniteScroll from 'vue-infinite-scroll'

export default {
  directives: { infiniteScroll },
  data() {
    return {
      loading: false,
      busy: false,
      datalist: {
        perday: [],
        permonth: [],
      },
      tgl1: null,
      tgl2: null,
      inputbulanan: null,
      calendarviewmodemonth: 'month',
      responsebulanan: [],
      backupDataBulanan: [],
      responseperhari: [],
      backupDataPerhari: [],
      modaldetails: {
        visible: false,
        operatorname: '',
        data: [],
      },
      componentKey: 0,
    }
  },
  created () {
    this.inputbulanan = moment()
    this.tgl1 = moment()
    this.tgl2 = moment()
    this.getAllData()
    this.getAllDataMonth()
  },
  methods: {
    moment,
    listOnClick(item) {
      // console.log('item', item)
      this.modaldetails.visible = true
      this.modaldetails.operatorname = item.name
      this.modaldetails.data = item.datalist
      // console.log('this.modaldetails.data', this.modaldetails.data)
      this.componentKey += 1
    },
    onCalendarChange(dateMoment, dateString) {
      // console.log('Change dateMoment', dateMoment)
      // console.log('Change dateString', dateString)
    },
    onCalendarSelect(dateMoment, dateString) {
      // console.log('Select dateMoment', dateMoment.format('YYYY-MM-DD'))
      // console.log('Select dateString', dateString)
      this.inputbulanan = dateMoment
      this.getAllDataMonth()
      if (this.calendarviewmodemonth === 'month') {
        this.tgl1 = dateMoment
        this.tgl2 = dateMoment
        this.getAllData()
      }
    },
    onPanelChange(dateMoment, mode) {
      // console.log(dateMoment, mode)
      this.inputbulanan = dateMoment
      this.getAllDataMonth()
      if (this.calendarviewmodemonth === 'month') {
        this.tgl1 = dateMoment
        this.tgl2 = dateMoment
        this.getAllData()
      }
      if (mode === 'month') {
        this.calendarviewmodemonth = mode
        this.componentKey += 1
      }
    },
    changePeriode(dateMoment, dateString, from) {
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('from', from)
      if (dateMoment.length === 0) {
        this.responseperhari = this.$g.clone(this.backupDataPerhari)
      } else {
        this[from] = dateMoment
        if (this.tgl1 !== null && this.tgl2 !== null) {
          this.getAllData()
        }
      }
      // if (dateMoment !== null) {
      //   this[from] = dateMoment.format('YYYY-MM-DD')
      //   this.filterDataByDate()
      // }
      // if (this.tgl1 === null && this.tgl2 === null) {
      //   this.data = this.$g.clone(this.backupData)
      // }
    },
    changePeriodeMonth(dateMoment, dateString, from) {
      // console.log('dateMoment', dateMoment)
      // console.log('dateString', dateString)
      // console.log('from', from)
      if (dateMoment === null) {
        this.responsebulanan = this.$g.clone(this.backupDataBulanan)
      } else {
        this[from] = dateMoment
        this.getAllDataMonth()
        // if (this.tgl1 !== null && this.tgl2 !== null) {
        //   this.getAllData()
        // }
      }
      // if (dateMoment !== null) {
      //   this[from] = dateMoment.format('YYYY-MM-DD')
      //   this.filterDataByDate()
      // }
      // if (this.tgl1 === null && this.tgl2 === null) {
      //   this.data = this.$g.clone(this.backupData)
      // }
    },
    async getAllDataMonth() {
      // console.log('this.inputbulanan', this.inputbulanan)
      var tgl1 = this.inputbulanan.clone().startOf('month')
      var tgl2 = this.inputbulanan.clone().endOf('month')
      // console.log('tgl1', tgl1.format('YYYY-MM-DD'))
      // console.log('tgl2', tgl2.format('YYYY-MM-DD'))
      var imbuhan =
        tgl1 !== null && tgl2 !== null
          ? '?tgl1=' +
            tgl1.format('YYYY-MM-DD') +
            '&tgl2=' +
            tgl2.format('YYYY-MM-DD')
          : ''
      var res = await lou.readMaster('mutasi' + imbuhan)
      if (res) {
        this.responsebulanan = res.data
        this.proccessData('responsebulanan', 'permonth')
      } else {
        this.responsebulanan = []
      }
    },
    proccessData(from, datalistto) {
      if (this[from].length !== 0) {
        var peruser = this[from].reduce(function(results, org) {
          (results[org.username] = results[org.username] || []).push(org)
          return results
        }, {})
        // console.log('peruser', peruser)
        var keysperuser = Object.keys(peruser)
        var returntoarr = []
        keysperuser.forEach((element) => {
          var afterfilter = this[from].filter(x => x.username === element)
          // console.log('element', element)
          // console.log('afterfilter', afterfilter)
          if (afterfilter.length !== 0) {
            returntoarr.push({
              name: element,
              datalist: afterfilter,
            })
            // console.log('name: ' + element, afterfilter)
          }
        })
        this.datalist[datalistto] = this.$g.clone(returntoarr)
      }
    },
    async getAllData() {
      var imbuhan =
        this.tgl1 !== null && this.tgl2 !== null
          ? '?tgl1=' +
            this.tgl1.format('YYYY-MM-DD') +
            '&tgl2=' +
            this.tgl2.format('YYYY-MM-DD')
          : ''
      var res = await lou.readMaster('mutasi' + imbuhan)
      if (res) {
        this.responseperhari = res.data
        this.proccessData('responseperhari', 'perday')
      } else {
        this.responseperhari = []
      }
    },
    getDynamicColor(index) {
      var ret = lou.dynamicColors(index)
      return ret
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
  },
}
</script>

<style lang="scss" scoped>
.demo-infinite-container {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 15px;
  height: 30vh;
}
.demo-loading-container {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.demo-infinite-container1 {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  overflow: auto;
  padding: 8px 15px;
  max-height: 70vh;
}
.demo-loading-container1 {
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
}
.ant-list-item-meta-title {
  line-height: 0 !important;
  padding-top: 5px !important;
}
.ant-modal-body {
  padding: unset !important;
}
</style>
